import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col, Card } from "react-bootstrap"

class VideoGalleryPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Capernwray Video Gallery" />
        <Container>
          <Row>
            <Col sm={12}>
              <h1 className="page-title my-4">Video Gallery</h1>
              <Card className="mb-5">
                <Card.Header className="d-flex justify-between">
                  <h3 className="">Videos from YouTube</h3>
                  <a
                    href="https://cprnwry.typeform.com/to/zVn5FZ4X"
                    className="btn btn-primary ms-auto"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Submit a video
                  </a>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col md={4} className="mb-3">
                      <iframe
                        title="Capernwray Video"
                        width="100%"
                        height="200"
                        src="https://www.youtube.com/embed/2uTA3mSCLCQ?si=Wq3Hu-PaxqWNQcOc"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </Col>
                    <Col md={4} className="mb-3">
                      <iframe
                        title="Capernwray Video"
                        width="100%"
                        height="200"
                        src="https://www.youtube.com/embed/hfw4YHyEEGE"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </Col>
                    <Col md={4} className="mb-3">
                      <iframe
                        title="Capernwray Video"
                        width="100%"
                        height="200"
                        src="https://www.youtube.com/embed/hg8IgUlBueg"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </Col>
                    <Col md={4} className="mb-3">
                      <iframe
                        title="Capernwray Video"
                        width="100%"
                        height="200"
                        src="https://www.youtube.com/embed/_6bWh6yhtSA?si=ZTr_uF4OqTbr01ZQ"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </Col>
                    <Col md={4} className="mb-3">
                      <iframe
                        title="Capernwray Video"
                        width="100%"
                        height="200"
                        src="https://www.youtube.com/embed/2uTA3mSCLCQ"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </Col>
                    <Col md={4} className="mb-3">
                      <iframe
                        title="Capernwray Video"
                        width="100%"
                        height="200"
                        src="https://www.youtube.com/embed/KIRzN0CPArU"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }
}

export default VideoGalleryPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    ImagesForGallery: allFile(
      filter: { sourceInstanceName: { eq: "gallery" } }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
